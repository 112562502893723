import axios from 'axios';

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh');

  if (!refreshToken) {
    console.error('No refresh token found.');
    return null;
  }

  try {
    const response = await axios.post('https://api.app.feba.ai/api/v1.0.0/accouunt/token/refresh/', {
      refresh: refreshToken,
    });

    // Update the access token in localStorage
    const newAccessToken = response.data.access;
    localStorage.setItem('access', newAccessToken);

    return newAccessToken;
  } catch (error) {
    console.error('Failed to refresh access token:', error.response?.data || error.message);
    localStorage.clear(); // Clear storage if refresh fails
    window.location.href = '/login'; // Redirect to login
    return null;
  }
};

export default refreshAccessToken;
