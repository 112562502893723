// src/pages/wizard/EventDetailsForm.js

import React from 'react';
import { TextField, Box } from '@mui/material';

function EventDetailsForm({ data, onDataChange }) {
  const handleChange = (e) => {
    onDataChange({ eventDetails: { ...data, [e.target.name]: e.target.value } });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
     
      <TextField
        label="These details help us make the best decision for your project needs * "
        name="description"
        value={data.description || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={8}
        required
      />
    </Box>
  );
}

export default EventDetailsForm;
