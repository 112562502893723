import axios from 'axios';
import refreshAccessToken from './utils/refreshAccessToken';

// Create Axios instance
const api = axios.create({
  baseURL: 'https://api.app.feba.ai/api/v1.0.0/',
});

// Flag to track if the token is being refreshed
let isRefreshing = false;
let refreshSubscribers = [];

// Helper to subscribe to the refreshed token
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// Notify all subscribers of the new token
const onTokenRefreshed = (newToken) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

// Request interceptor to attach the access token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for handling token expiration
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401 Unauthorized
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        // Wait for the token to be refreshed
        return new Promise((resolve) => {
          subscribeTokenRefresh((newToken) => {
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            resolve(api(originalRequest)); // Retry with new token
          });
        });
      }

      // Start refreshing the token
      isRefreshing = true;
      try {
        const newAccessToken = await refreshAccessToken();

        if (newAccessToken) {
          localStorage.setItem('access', newAccessToken); // Save new token
          onTokenRefreshed(newAccessToken); // Notify subscribers

          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return api(originalRequest); // Retry the original request
        }
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        localStorage.removeItem('access'); // Clear invalid token
        window.location.href = '/login'; // Redirect to login
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error); // Reject all other errors
  }
);

export default api;
