// src/pages/wizard/CoordinateForm.js

import React from 'react';
import { TextField, Box } from '@mui/material';

function CoordinateForm({ data, onDataChange }) {
  const handleChange = (e) => {
    onDataChange({ coordinate: { ...data, [e.target.name]: e.target.value } });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Briefly describe your requirement * "
        name="supply"
        value={data.supply || ''}
        onChange={handleChange}
        fullWidth
        multiline
        rows={2}
        required
      />
      <TextField
        label="Write the email address of the desired provider * "
        name="supplierEmail"
        type="email"
        value={data.supplierEmail || ''}
        onChange={handleChange}
        fullWidth
        required
      />
    </Box>
  );
}

export default CoordinateForm;
