import axios from 'axios';

export const startTokenVerification = () => {
  const excludedPaths = ['/login', '/register', '/forgot-password'];

  const verifyAccessToken = async () => {
    const accessToken = localStorage.getItem('access');
    const currentPath = window.location.pathname;

    // Skip verification if on an excluded path
    if (excludedPaths.includes(currentPath)) {
      return;
    }

    // Redirect to login if access token does not exist
    if (!accessToken) {
      console.warn('Access token not found. Redirecting to login...');
      window.location.href = '/login';
      return;
    }

    try {
      // Call token verification API
      await axios.post('https://api.app.feba.ai/api/v1.0.0/account/token/verify/', { token: accessToken });
      console.log('Access token is valid');
    } catch (error) {
      console.error('Access token is invalid. Redirecting to login...');
      localStorage.clear();
      window.location.href = '/login';
    }
  };

  // Run verification every minute
  setInterval(verifyAccessToken, 60 * 1000);

  // Run immediately on load
  verifyAccessToken();
};
