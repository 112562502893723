import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography, Paper, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();

  // Send code to email
  const handleSendCode = async () => {
    try {
      const response = await axios.post('https://api.app.feba.ai/api/v1.0.0/account/reset-password-code/', {
        email,
      });
      if (response.status === 200) {
        setCodeSent(true);
        setSuccess('Code sent to your email. Please check your inbox.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to send code. Try again.');
    }
  };

  // Submit new password and code
  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    }
    try {
      const response = await axios.post('https://api.app.feba.ai/api/v1.0.0/account/reset-password/', {
        email,
        code,
        password,
      });

      if (response.status === 200) {
        setError('');
        setSuccess('Password reset successful! Redirecting to login...');
        setTimeout(() => navigate('/login'), 2000);
      }
    } catch (err) {
      setSuccess('');
      setError(err.response?.data?.message || 'Failed to reset password. Try again.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, mt: 5 }}>
        <Typography variant="h4" color="primary" gutterBottom textAlign="center">
          Reset Password
        </Typography>
        {success && <Typography color="success" textAlign="center">{success}</Typography>}
        {error && <Typography color="error" textAlign="center">{error}</Typography>}
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Email Input */}
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            disabled={codeSent}
            required
          />
          {/* Send Code Button */}
          {!codeSent && (
            <Button variant="contained" color="primary" onClick={handleSendCode}>
              Send Code
            </Button>
          )}

          {/* Code Input */}
          {codeSent && (
            <>
              <TextField
                label="Validation Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                required
              />
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}

          {/* Back to Login */}
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Link href="/login" underline="hover">
              Back to Login
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default ResetPasswordPage;
