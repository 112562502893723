import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

function EmailGenerationRulesForm({ data, onDataChange }) {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [manualRules, setManualRules] = useState(data.manualRules || []);
  const [newRule, setNewRule] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!Object.keys(data).some((key) => key.startsWith('question_'))) {
      fetchQuestions();
    }
  }, []);

  const fetchQuestions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://api.app.feba.ai/api/v1.0.0/negotiate/generate-negotiation-questions/');
      const { questions } = response.data;
      if (questions && questions.length > 0) {
        setQuestions(questions);
      } else {
        setError('No questions found.');
      }
    } catch (err) {
      setError('Failed to fetch questions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (e, index) => {
    const updatedData = { ...data, [`question_${index}`]: e.target.value };
    onDataChange({ emailGenerationRules: updatedData });
  };

  const deleteAnswer = (index) => {
    const updatedData = { ...data };

    // Remove the selected question
    delete updatedData[`question_${index}`];

    // Shift remaining questions
    const shiftedData = {};
    let shiftedIndex = 0;
    Object.keys(updatedData)
      .filter((key) => key.startsWith('question_'))
      .sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1])) // Ensure proper ordering
      .forEach((key) => {
        shiftedData[`question_${shiftedIndex}`] = updatedData[key];
        shiftedIndex++;
      });

    onDataChange({ emailGenerationRules: shiftedData });
  };

  const handleNewRuleChange = (e) => setNewRule(e.target.value);

  const addManualRule = () => {
    if (newRule.trim() !== '') {
      const updatedManualRules = [...manualRules, newRule];
      setManualRules(updatedManualRules);
      setNewRule('');
      onDataChange({ emailGenerationRules: { ...data, manualRules: updatedManualRules } });
    }
  };

  const removeManualRule = (index) => {
    const updatedManualRules = manualRules.filter((_, i) => i !== index);
    setManualRules(updatedManualRules);
    onDataChange({ emailGenerationRules: { ...data, manualRules: updatedManualRules } });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <label>The following questions are to help generate the best email communication rules for your needs.</label>
      
      {/* Load new questions button */}
      {questions.length === 0 && (
        <Button variant="contained" color="primary" onClick={fetchQuestions} disabled={loading}>
          {loading ? 'Loading...' : 'Load New Questions'}
        </Button>
      )}

      {error && <Typography color="error">{error}</Typography>}

      {Object.keys(data)
        .filter((key) => key.startsWith('question_'))
        .map((key, index) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              label={`Answer to: ${questions[index] || `Question ${index + 1}`}`}
              value={data[key]}
              onChange={(e) => handleAnswerChange(e, index)}
              fullWidth
              multiline
            />
            <IconButton onClick={() => deleteAnswer(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}

      {questions.length > 0 && (
        <>
          <Typography variant="h6">{questions[currentQuestionIndex]}</Typography>
          <TextField
            label="Your Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            fullWidth
            multiline
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                const updatedData = { ...data, [`question_${currentQuestionIndex}`]: answer };
                onDataChange({ emailGenerationRules: updatedData });
                setAnswer('');
                setCurrentQuestionIndex((prev) => prev + 1);
              }}
              disabled={currentQuestionIndex >= questions.length - 1}
            >
              Another
            </Button>
          </Box>
        </>
      )}

      {/* Add Manual Rule Section */}
      <Box>
        <Typography variant="h6">Add Manual Information</Typography>
        <label>If you have additional rules not covered in the questions, enter them here.</label>
        <TextField
          label="Manual Rule"
          value={newRule}
          onChange={handleNewRuleChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={addManualRule}
          sx={{ marginTop: 2 }}
        >
          Add
        </Button>
        {manualRules.length > 0 && (
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1">Manual Rules:</Typography>
            {manualRules.map((rule, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 2,
                  marginBottom: 1,
                }}
              >
                <TextField
                  value={rule}
                  onChange={(e) => {
                    const updatedManualRules = [...manualRules];
                    updatedManualRules[index] = e.target.value;
                    setManualRules(updatedManualRules);
                    onDataChange({
                      emailGenerationRules: { ...data, manualRules: updatedManualRules },
                    });
                  }}
                  fullWidth
                />
                <IconButton
                  onClick={() => removeManualRule(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default EmailGenerationRulesForm;
