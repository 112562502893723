import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import axios from 'axios';
import api from '../../authMiddleware';

function ConfirmationForm({ data, onSubmit }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [combinedPurchaseRules, setCombinedPurchaseRules] = useState([]);
  const [combinedEmailRules, setCombinedEmailRules] = useState([]);
  const [manualPurchaseRules, setManualPurchaseRules] = useState([]);
  const [manualEmailRules, setManualEmailRules] = useState([]);
  const [eventDetails, setEventDetails] = useState(data.eventDetails);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.post(
          'https://api.stage.feba.ai/api/v1/agents/coordinate/eventname/',
          {
            description: data.eventDetails.description,
          }
        );

        if (response.data && response.data.name) {
          setEventDetails((prev) => ({
            ...prev,
            name: response.data.name,
          }));
        }
      } catch (err) {
        console.error('Error fetching event details:', err);
        setError('Failed to load event details.');
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();

    // Fetch updated purchase and email rules dynamically from `data`
    const purchaseQuestions = [];
    const purchaseAnswers = [];
    Object.keys(data.purchaseRules || {})
      .filter((key) => key.startsWith('question_'))
      .sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1])) // Ensure order
      .forEach((key) => {
        purchaseQuestions.push(data.purchaseRules[key]?.question || `Question ${key}`);
        purchaseAnswers.push(data.purchaseRules[key]);
      });

    const emailQuestions = [];
    const emailAnswers = [];
    Object.keys(data.emailGenerationRules || {})
      .filter((key) => key.startsWith('question_'))
      .sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1])) // Ensure order
      .forEach((key) => {
        emailQuestions.push(data.emailGenerationRules[key]?.question || `Question ${key}`);
        emailAnswers.push(data.emailGenerationRules[key]);
      });

    const manualPurchase = data.purchaseRules?.manualRules || [];
    const manualEmail = data.emailGenerationRules?.manualRules || [];

    setManualPurchaseRules(manualPurchase);
    setManualEmailRules(manualEmail);

    combinePurchaseRules(purchaseQuestions, purchaseAnswers);
    combineEmailRules(emailQuestions, emailAnswers);
  }, [data]);

  const combinePurchaseRules = async (questions, answers) => {
    try {
      const response = await axios.post(
        'https://api.stage.feba.ai/api/v1/agents/coordinate/combine/',
        { questions, answers }
      );
      if (response.data && Array.isArray(response.data.rules)) {
        setCombinedPurchaseRules(response.data.rules);
      }
    } catch {
      setError('Failed to combine purchase rules.');
    }
  };

  const combineEmailRules = async (questions, answers) => {
    try {
      const response = await axios.post(
        'https://api.stage.feba.ai/api/v1/agents/email/combine/',
        { questions, answers }
      );
      if (response.data && Array.isArray(response.data.rules)) {
        setCombinedEmailRules(response.data.rules);
      }
    } catch {
      setError('Failed to combine email generation rules.');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const eventResponse = await api.post('/negotiate/events/', {
        name: eventDetails.name,
        description: eventDetails.description,
      });
      const eventId = eventResponse.data.id;

      const emailRuleIds = [];
      for (const rule of combinedEmailRules) {
        const emailRuleResponse = await api.post(
          '/negotiate/email-generation-rules/',
          { rule }
        );
        emailRuleIds.push(emailRuleResponse.data.id);
      }

      const purchaseRuleIds = [];
      for (const rule of combinedPurchaseRules) {
        const purchaseRuleResponse = await api.post(
          '/negotiate/purchase-rules/',
          { rule }
        );
        purchaseRuleIds.push(purchaseRuleResponse.data.id);
      }

      await api.post('/negotiate/negotiations/', {
        name: data.coordinate.name,
        supply: data.coordinate.supply,
        supplier: data.coordinate.supplierEmail,
        event: eventId,
        purchaserules: purchaseRuleIds,
        emailgenerationrules: emailRuleIds,
      });

      setSuccess(true);
      setSnackbarOpen(true);
      onSubmit();
    } catch (err) {
      setError('An error occurred while submitting the data.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      window.location.href = '/events';
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              padding: 2,
              border: '1px solid #ddd',
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
            }}
          >
            <Typography variant="h6">Project Details</Typography>
            <Typography>
              <strong>Project Description:</strong> {eventDetails.description}
            </Typography>

            <Typography variant="h6" sx={{ mt: 3 }}>
              Combined Purchase Rules
            </Typography>
            {combinedPurchaseRules.map((rule, index) => (
              <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography>{rule}</Typography>
              </Paper>
            ))}

            <Typography variant="h6" sx={{ mt: 3 }}>
              Manually Added Purchase Rules
            </Typography>
            {manualPurchaseRules.map((rule, index) => (
              <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography>{rule}</Typography>
              </Paper>
            ))}

            <Typography variant="h6" sx={{ mt: 3 }}>
              Combined Email Generation Rules
            </Typography>
            {combinedEmailRules.map((rule, index) => (
              <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography>{rule}</Typography>
              </Paper>
            ))}

            <Typography variant="h6" sx={{ mt: 3 }}>
              Manually Added Email Generation Rules
            </Typography>
            {manualEmailRules.map((rule, index) => (
              <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography>{rule}</Typography>
              </Paper>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 3 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={success ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {success ? 'Submission successful!' : error || 'An error occurred.'}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConfirmationForm;
