// src/pages/LoginPage.js

import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography, Paper, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../authMiddleware';
import { CenterFocusStrong } from '@mui/icons-material';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await api.post('/account/login/', { username, password });
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      navigate('/');
    } catch {
      setError('Invalid credentials');
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, mt: 5 ,textAlign: 'center'}}>
        <Typography variant="h4" color="primary" gutterBottom>
          Login
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <Button variant="contained" onClick={handleLogin}>
            Login
          </Button>
          <Box sx={{ textAlign: 'center', mt: 0 }}>
            <Link href="/register" underline="hover">
              Don't have an account?
            </Link><br></br>
            <Link href="/forgot-password" underline="hover">
              Don't remember your password? 
            </Link>
          </Box>


        </Box>
      </Paper>
    </Container>
  );
}

export default LoginPage;
