import React, { useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, Paper, Container, Snackbar, Alert } from '@mui/material';
import EventDetailsForm from './wizard/EventDetailsForm';
import PurchaseRulesForm from './wizard/PurchaseRulesForm';
import EmailGenerationRulesForm from './wizard/EmailGenerationRulesForm';
import CoordinateForm from './wizard/CoordinateForm';
import ConfirmationForm from './wizard/ConfirmationForm';

function WizardPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    eventDetails: {},
    purchaseRules: {},
    emailGenerationRules: {},
    coordinate: {},
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const steps = [
    'Project Details',
    'Counterparty details',
    'Additional information',
    'Email Generation Rules',
    'Confirmation',
  ];

  const handleNext = () => {
    if (activeStep === 0) {
      // Validate the Event Details form
      if (!formData.eventDetails.description) {
        setErrorMessage('Project description is required.');
        setOpenSnackbar(true);
        return; // Prevent moving to the next step
      }
    }

    if (activeStep === 1) {
      // Validate the Coordinate Form
      if (!formData.coordinate.supply || !formData.coordinate.supplierEmail) {
        setErrorMessage('Both Counterparty Request and Counterparty Email are required.');
        setOpenSnackbar(true);
        return; // Prevent moving to the next step
      }

      // Email Validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(formData.coordinate.supplierEmail)) {
        setErrorMessage('Please enter a valid email address.');
        setOpenSnackbar(true);
        return; // Prevent moving to the next step
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const handleStartAgain = () => setActiveStep(0);

  const handleDataChange = (stepData) => {
    setFormData((prevData) => ({ ...prevData, ...stepData }));
  };

  const handleSubmit = () => {
    console.log('Final data:', formData);
    setActiveStep(activeStep + 1);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" color="primary" gutterBottom>
        AI Coordinator
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper sx={{ padding: 3, marginTop: 3 }}>
        {activeStep === 0 && (
          <EventDetailsForm
            data={formData.eventDetails}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 1 && (
          <CoordinateForm
            data={formData.coordinate}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 2 && (
          <PurchaseRulesForm
            eventDescription={formData.eventDetails.description}
            supply={formData.coordinate.supply}
            data={formData.purchaseRules}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 3 && (
          <EmailGenerationRulesForm
            data={formData.emailGenerationRules}
            onDataChange={handleDataChange}
          />
        )}
        {activeStep === 4 && (
          <ConfirmationForm
            data={formData}
            onConfirm={handleSubmit}
          />
        )}
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        {activeStep > 0 && activeStep < steps.length - 1 && (
          <Button variant="outlined" color="primary" onClick={handleBack}>
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button variant="outlined" color="primary" onClick={handleStartAgain}>
          Start Again
        </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default WizardPage;
