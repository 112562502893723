import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import api from '../api';

const ProtectedRoute = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const accessToken = localStorage.getItem('access');
      if (!accessToken) {
        // No token, redirect to login
        setLoading(false);
        return;
      }

      try {
        // Verify the access token
        await api.post('/account/token/verify/', { token: accessToken });
        setIsVerified(true);
      } catch {
        // If token verification fails, clear storage and redirect
        localStorage.clear();
        window.location.href = '/login';
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, []);

  // Display spinner while verifying the token
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isVerified ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
